import React from "react";
import discord from "./../../assets/images/discord-100.png";
import twitter from "./../../assets/images/twitter-100.png";
import "./Footer.css";

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="section footer wf-section">
          <div className="divider mb-40"></div>
          <div className="footer-links">
            <div className="footer-lonks-left">
              <p className="text-extra-small align-left text-white-opacity mb-10">
                MONKAI. All Rights Reserved.{" "}
              </p>
            </div>
            <div className="subscribe-socials community">
              <a
                href="https://twitter.com/monkainft"
                className="footer-social community w-inline-block"
              >
                <img
                  src={twitter}
                  loading="lazy"
                  width={30}
                  height={30}
                  alt="Twitter"
                  className="image-10"
                />
              </a>
              <a
                href="https://discord.gg/monkai"
                className="footer-social community w-inline-block"
              >
                <img
                  src={discord}
                  loading="lazy"
                  width={30}
                  alt="Discord"
                  height={30}
                  className="image-10"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
