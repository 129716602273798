import React from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";

import bannerWebp from "./../../../images/monkai_promo_cut2.webp";
import bannerPng from "./../../../images/monkai_promo_cut2.png";

export default function Overview() {
  const overviewItems = [
    {
      title: "Gen 0 Reward",
      content: "69 ",
      span: "$MONK/week",
    },
    {
      title: "Gen 1 Reward",
      content: "30 ",
      span: "$MONK/week",
    },
    {
      title: "Gen 2 Reward",
      content: "9 ",
      span: "$MONK/week",
    },
    {
      title: "Staking Dates",
      content: "Feb 21 - Feb 27 ",
    },
    {
      title: "Claim Date",
      content: "Feb 28",
    },
  ];
  return (
    <div className={styles.overviewContainer}>
      <div className={styles.gallery}>
        <h1 className={styles.galleryTitle}> </h1>
        <div className={styles.galleryContainer}>
          <a href="#staking" className={styles.logo}>
            {/* 
            <img
              className={styles.galleryImage}
              src={require(`./../../../images/monkai_promo_cut2.webp`).default}
              alt="Banner"
            /> */}
            <picture>
              <source srcSet={bannerWebp} type="image/webp" />
              <source srcSet={bannerPng} type="image/jpeg" />
              <img
                className={styles.galleryImage}
                src={bannerPng}
                alt="Banner"
              />
            </picture>
          </a>
        </div>
      </div>
      <div id="staking" className={styles.titleContainer}>
        <div className={styles.line}></div>
        <h6 className={styles.overviewTitle}>STAKING</h6>
        <div className={styles.line}></div>
      </div>
      <div className={styles.overviewItemsContainer}>
        <div className={styles.overviewItems}>
          {overviewItems.map((item) => {
            return (
              <div className={styles.overviewItemContainer} key={item.title}>
                <div className={styles.overviewItem}>
                  <p className={styles.overviewItemTitle}>{item.title}</p>
                  <p className={styles.overviewItemContent}>
                    {item.content}
                    {item.span}
                  </p>
                  <p className={styles.overviewItemDescription}>
                    {item.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
