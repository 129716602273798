import React from "react";
import cn from "classnames";
import styles from "./CardNft.module.sass";

export default function CardNft({ nft, toStake, handleStake, staked }) {
  let selected = false;
  toStake?.forEach((element) => {
    if (nft.mint.equals(element.mint)) {
      selected = true;
    }
  });

  return (
    <div
      className={cn(
        styles.card,
        selected ? styles.bgSelected : styles.bgUnselected
      )}
      onClick={() => {
        if (handleStake) handleStake(nft, toStake);
      }}
    >
      <small># {nft.data.name.split("#").at(-1)}</small>
      <img
        src={nft.json.image}
        alt={nft.name}
        className={styles.image}
        loading="lazy"
      />
      <div
        class={staked && styles.circle}
        style={{ animationDelay: "-3s" }}
      ></div>
      <div
        class={staked && styles.circle}
        style={{ animationDelay: "-2s" }}
      ></div>
      <div
        class={staked && styles.circle}
        style={{ animationDelay: "-1s" }}
      ></div>
      <div
        class={staked && styles.circle}
        style={{ animationDelay: "0s" }}
      ></div>
    </div>
  );
}
