import React, { useState, useEffect, useCallback, useReducer } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import cn from "classnames";
import { VscSettings } from "react-icons/vsc";
import { ToastContainer, toast } from "react-toastify";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import Button from "../../components/ButtonContainer";

import ReactTooltip from "react-tooltip";
import "react-toastify/dist/ReactToastify.css";

import styles from "./Stake-nfts.module.sass";
import CardNft from "./CardNft";
import CardToStake from "./CardToStake";
import Overview from "./Overview";

import { useStakedNfts } from "../../hooks/useStakedNfts";
import { useUnstakedNfts } from "../../hooks/useUnstakedNfts";
import {
  createStakeTokenTransaction,
  createUnstakeTokenTransaction,
  createWithdrawRewardTransaction,
  getRewardCalculator,
  calculateRewards,
  StakedData,
  UnstakedData,
  RewardCalculator,
} from "../../util/staking";

import stalkingVector from "./../../images/Vector.png";

import exampleImgNft from "./../../images/mint.jpg";
import ButtonContainer from "../../components/ButtonContainer";

export default function StakeNfts() {
  const [showNft, setShowNft] = useState(false);
  const [toStake, setToStake] = useState([]);
  const [txInProgress, setTxInProgress] = useState(false);
  /* const price = 42; */

  const { connection } = useConnection();
  const { wallet, publicKey, signTransaction, signAllTransactions } =
    useWallet();

  const [refreshHandle, forceRefresh] = useReducer((x) => !x, true);

  const stakedNfts = useStakedNfts(refreshHandle);
  const unstakedNfts = useUnstakedNfts(refreshHandle);
  const [nfts, setNfts] = useState([]);
  /* const stakedNfts = [
    {
      json: {
        image: exampleImgNft,
      },
      data: {
        name: "holis",
      },
    },
  ]; */
  const [bulkSignTransactions, setBulkSignTransactions] = useState(true);

  const hardwareOnClick = useCallback((e) =>
    setBulkSignTransactions(!bulkSignTransactions)
  );

  useEffect(() => {
    setNfts(stakedNfts.concat(unstakedNfts));
    setShowNft(unstakedNfts.length > 0 || stakedNfts.length > 0);
  }, [stakedNfts, unstakedNfts]);

  const performActionOnTokens = useCallback(
    (tokens, action) => {
      if (!publicKey) return;

      setTxInProgress(true);

      const promise = new Promise(async (resolve, reject) => {
        try {
          let transactions = await Promise.all(
            tokens.map(({ mint }) => action(connection, publicKey, mint))
          );
          transactions.forEach(
            (transaction) => (transaction.feePayer = publicKey)
          );

          let transactionSignatures = [];
          if (bulkSignTransactions) {
            let { blockhash } = await connection.getRecentBlockhash();
            transactions.forEach(
              (transaction) => (transaction.recentBlockhash = blockhash)
            );

            let signedTransactions = await signAllTransactions(transactions);
            transactionSignatures = await Promise.all(
              signedTransactions.map((signedTransaction) =>
                connection.sendRawTransaction(signedTransaction.serialize())
              )
            );
          } else {
            let sendTransactionPromises = [];
            for (let transaction of transactions) {
              let { blockhash } = await connection.getRecentBlockhash();
              transaction.recentBlockhash = blockhash;
              let signedTransaction = await signTransaction(transaction);
              sendTransactionPromises.push(
                connection.sendRawTransaction(signedTransaction.serialize())
              );
            }
            transactionSignatures = await Promise.all(sendTransactionPromises);
          }

          await Promise.all(
            transactionSignatures.map(async (signature) => {
              await connection.confirmTransaction(signature, "processed");
              console.log(signature);
            })
          );

          await new Promise((resolve) => setTimeout(resolve, 2000));

          forceRefresh();
          setToStake([]);
          resolve();
        } catch (err) {
          console.error(err);
          setTxInProgress(false);
          reject();
        }
      });

      toast.promise(promise, {
        pending: "Making transaction...",
        success: "Transaction has been confirmed",
        error: "Transaction failed",
      });
    },
    [connection, publicKey, bulkSignTransactions]
  );

  const changeSelected = (nft) => {
    return {
      ...nft,
      selected: !nft.selected,
    };
  };

  const handleStake = (nft, toStake) => {
    let selected = false;

    toStake.forEach((item, index) => {
      if (nft.mint.equals(item.mint)) {
        selected = true;

        setToStake(
          toStake.filter((item, index) => {
            return !nft.mint.equals(item.mint);
          })
        );
      }
    });

    if (!selected) {
      setToStake(toStake.concat(changeSelected({ ...nft })));
    }
  };

  return (
    <div>
      <Overview />
      <div className={styles.stakeNfts}>
        <div className={styles.main}>
          <div className={styles.options}>
            <div className={styles.apr}>
              <img className={styles.logo} src={stalkingVector} alt="Logo" />
              <div className={styles.title}>
                <h2>$MONK</h2>
                <h3>MONKAI</h3>
              </div>
            </div>
          </div>

          <div className={showNft ? styles.nftsHidden : styles.nftsList}>
            {/* 
            <h1>You have no eligible Flowers to Stake</h1> */}
            <div className={styles.buttonContainer}>
              <ButtonContainer handleClick={() => setShowNft(!showNft)}>
                <button>Explore Vaults</button>
              </ButtonContainer>
              <ButtonContainer>
                <button>Change Wallet</button>
              </ButtonContainer>
            </div>
          </div>

          <div
            className={cn(showNft ? styles.cardsContainer : styles.nftsHidden)}
          >
            <div className={cn(styles.stakedTitles)}>
              <h2>
                {stakedNfts.length} Staked Item
                {stakedNfts.length == 1 ? "" : "s"}
              </h2>
              <div className={cn(styles.stakedButtons)}>
                {wallet && (
                  <button
                    className={styles.buttonConnect}
                    onClick={() =>
                      performActionOnTokens(
                        stakedNfts,
                        createUnstakeTokenTransaction
                      )
                    }
                    disabled={txInProgress}
                  >
                    UNSTAKE ALL
                  </button>
                )}
              </div>
            </div>

            <div className={styles.cards}>
              {stakedNfts.map((nft) => {
                return (
                  <CardNft
                    key={nft.mint}
                    nft={nft}
                    toStake={toStake}
                    handleStake={handleStake}
                    staked={true}
                  />
                );
              })}
            </div>
          </div>

          <div
            className={cn(showNft ? styles.cardsContainer : styles.nftsHidden)}
          >
            <div className={cn(styles.stakedTitles)}>
              <h2>
                {unstakedNfts.length} Item{unstakedNfts.length == 1 ? "" : "s"}
              </h2>
              <div className={cn(styles.stakedButtons)}>
                {wallet && (
                  <button
                    className={styles.buttonConnect}
                    onClick={() =>
                      performActionOnTokens(
                        unstakedNfts,
                        createStakeTokenTransaction
                      )
                    }
                    disabled={txInProgress}
                  >
                    STAKE ALL
                  </button>
                )}
              </div>
            </div>
            <div className={styles.cards}>
              {unstakedNfts.map((nft) => {
                return (
                  <CardNft
                    key={nft.mint}
                    nft={nft}
                    toStake={toStake}
                    handleStake={handleStake}
                    staked={false}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className={styles.selected}>
          <div className={styles.stakingTitle}>
            <h2>You have selected ({toStake.length ? toStake.length : 0})</h2>
          </div>
          <div className={styles.stakingList}>
            {toStake.map((item) => {
              return (
                <CardToStake
                  key={item.mint}
                  nft={item}
                  toStake={toStake}
                  handleStake={handleStake}
                />
              );
            })}
          </div>

          {!wallet && (
            <ButtonContainer>
              <WalletMultiButton />
            </ButtonContainer>
          )}
          {wallet && (
            <ButtonContainer
              handleClick={() =>
                performActionOnTokens(toStake, createStakeTokenTransaction)
              }
              disabled={txInProgress}
            >
              <button>STAKE</button>
            </ButtonContainer>
          )}
          {wallet && (
            <ButtonContainer
              handleClick={() =>
                performActionOnTokens(toStake, createUnstakeTokenTransaction)
              }
              disabled={txInProgress}
            >
              <button>UNSTAKE</button>
            </ButtonContainer>
          )}
          {wallet && (
            <ButtonContainer
              handleClick={() =>
                performActionOnTokens(
                  stakedNfts,
                  createWithdrawRewardTransaction
                )
              }
              disabled={txInProgress}
            >
              <button>HARVEST</button>
            </ButtonContainer>
          )}
          {wallet && (
            <Checkbox
              label="Hardware Wallet Mode"
              value={!bulkSignTransactions}
              onChange={hardwareOnClick}
            />
          )}
        </div>
      </div>

      <ToastContainer
        theme="dark"
        position="bottom-left"
        hideProgressBar
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        closeButton={false}
      />
    </div>
  );
}

const Checkbox = ({ label, value, onChange }) => {
  return (
    <div>
      <a data-tip data-for="sadFace">
        <label className={cn(styles.control, styles.controlCheckbox)}>
          <input type="checkbox" checked={value} onChange={onChange} />
          <div className={styles.controlIndicator}></div>
          {label}
        </label>
      </a>
      <ReactTooltip id="sadFace" place="top" type="dark" effect="solid">
        <span>Sign and send each transaction individually.</span>
      </ReactTooltip>
    </div>
  );
};
