import React from "react";
import styles from "./Header.module.sass";
import { FaTwitter, FaDiscord } from "react-icons/fa";
import cn from "classnames";

const Headers = ({ home }) => {
  const socials = [
    {
      name: "discord",
      path: "https://discord.gg/monkai",
      image: <FaDiscord />,
    },
    {
      name: "twitter",
      path: "https://twitter.com/monkainft",
      image: <FaTwitter />,
    },
  ];
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <div className={cn(styles.wrapper, styles.visibleNav)}>
          <nav className={styles.nav}>
            {socials.map((social) => {
              return (
                <a
                  key={social.name}
                  className={styles.social}
                  href={social.path}
                >
                  {social.image}
                </a>
              );
            })}
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Headers;
