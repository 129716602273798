import React from "react";
import discord from "./../../assets/images/discord-100.png";
import twitter from "./../../assets/images/twitter-100.png";
import "./ButtonContainer.sass";

export default function ButtonContainer({ children, handleClick }) {
  return (
    <div className="custom-button primary w-inline-block" onClick={handleClick}>
      <div className="custom-button-hover white"></div>
      <div className="corner-black top-left small"></div>
      <div className="corner-black bottom-right small"></div>
      <div className="custom-button-text-2">{children}</div>
    </div>
  );
}
