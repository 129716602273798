import React from "react";
import cn from "classnames";
import styles from "./CardToStake.module.sass";
import { IoClose } from "react-icons/io5";

export default function CardToStake({ nft, toStake, handleStake }) {
  return (
    <div className={styles.toStake}>
      <div className={styles.toStakeImage}>
        <img src={nft.json.image} alt={nft.data.name} />
      </div>
      <div className={styles.stakeDescriptions}>
        <div className={styles.toStakeSmalls}>
          <small className={styles.smallTitle}>{nft.data.name}</small>
        </div>
        <div
          className={styles.deleteStake}
          onClick={() => {
            handleStake(nft, toStake);
          }}
        >
          <button>
            <IoClose />
          </button>
        </div>
      </div>
    </div>
  );
}
